import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './assets/scss/navbar.module.scss';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import base_doc from '../../assets/iconsNavOut/base_doc-icon.png';
import offres_emploi from '../../assets/iconsNavOut/offres_emploi-icon.png';
import handicap from '../../assets/iconsNavOut/handicap-icon.png';
import e_learning from '../../assets/iconsNavOut/e_learning-icon.png';
import { useApp } from '../../services/auth/useApp';
import ExtensionIcon from '@mui/icons-material/Extension';
import { App } from '../../services/object/App';
import { useLaw } from '../../services/auth/useLaw';


const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [gestionMenuOpen, setGestionMenuOpen] = useState(false);
  const location = useLocation();
  const drawerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLInputElement>(null);
  const gestionPages = ['/utilisateurs', '/concession', '/popins', '/accueilConfig'];
  const isOpen = gestionPages.includes(location.pathname);
  const { law } = useLaw();
  const { TOApp } = useApp();

  const toggleDrawer = () => {
    if (!drawerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    setDrawerOpen(!drawerOpen);
  }

  const handleClickGestionMenu = () => {
    setGestionMenuOpen(!gestionMenuOpen);
  }

  useEffect(() => {
    setGestionMenuOpen(isOpen);
  }, [location.pathname]);

  const closeDrawer = () => {
    setDrawerOpen(false);
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setDrawerOpen(false);
    }
  };

  return (
    <div>
      <React.Fragment key={'left'}>
        <div className={style.offcanvasMenu}>
          <input
            ref={buttonRef}
            onClick={toggleDrawer}
            type='checkbox'
            role='button'
            aria-label='Display the menu'
            className={style.menuButton}
            checked={drawerOpen}
          />
        </div>
        <Drawer
          ref={drawerRef}
          anchor={'left'}
          open={drawerOpen}
          className={`nav ${style.nav}`}
          onClose={toggleDrawer}
        >
          <Box className={style.box}>
            <List className={style.list}>

              <ListItem
                disablePadding
                className={style.listitem + ' ' + (location.pathname === '/accueil' ? style.active : '')}
              >
                <Link onClick={closeDrawer} to='/accueil'>
                  <ListItemButton>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    Accueil
                  </ListItemButton>
                </Link>
              </ListItem>

              <ListItem
                disablePadding
                className={style.listitem + ' ' + (location.pathname === '/toutes-actus' ? style.active : '')}
              >
                <Link onClick={closeDrawer} to='/toutes-actus'>
                  <ListItemButton>
                    <ListItemIcon><NewspaperIcon /></ListItemIcon>
                    Actualités
                  </ListItemButton>
                </Link>
              </ListItem>

              {TOApp
                .filter((app: App) => app.isJraApp === true && !(app.uid === '655cb071f404d0.41655118'))
                .map((app, index) => {
                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      className={style.listitem + ' ' + (location.pathname === app.url ? style.active : '')}
                    >
                      <Link onClick={closeDrawer} target="_blank" to={app.url}>
                        <ListItemButton>
                          <ListItemIcon>
                            {app.photo ? (
                              <div className={style.iconUrl}>
                                <img src={app.photo} alt={`${app.name} favicon`} />
                              </div>
                            ) : (
                              <ExtensionIcon />
                            )}
                          </ListItemIcon>
                          {app.name}
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  );
                })}

              <hr />

              <div className={style.boxNavOut}>
                <ListItem
                  disablePadding
                  className={style.listitem}
                >
                  <Link to="https://sites.google.com/jeanrouyerautomobiles.fr/base-documentaire/accueil" target="_blank">
                    <ListItemButton className='window'>
                      <div>
                        <img src={base_doc} className={style.iconNavOut} alt="Base documentaire" />
                        Base documentaire
                      </div>
                      <OpenInNewIcon />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem
                  disablePadding
                  className={style.listitem}
                >
                  <Link to="https://www.jeanrouyerautomobiles.fr/recrutement/recrutement/offres-demploi/" target="_blank">
                    <ListItemButton className='window'>
                      <div>
                        <img src={offres_emploi} className={style.iconNavOut} alt="Offres d'emploi" />
                        Offres d'emploi
                      </div>
                      <OpenInNewIcon />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem
                  disablePadding
                  className={style.listitem}
                >
                  <Link to="/accueilConfig">
                    <ListItemButton className='window'>
                      <div>
                        <img src={handicap} className={style.iconNavOut} alt="Accompagnement handicap" />
                        Accompagnement handicap
                      </div>
                      <OpenInNewIcon />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem
                  disablePadding
                  className={style.listitem}
                >
                  <Link to="/accueilConfig">
                    <ListItemButton className='window'>
                      <div>
                        <img src={e_learning} className={style.iconNavOut} alt="E-learning" />
                        E-learning
                      </div>
                      <OpenInNewIcon />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </div>
            </List>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  )
};

export default Navbar;