import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { NavLink } from 'react-router-dom';
import style from './assets/scss/lienUtile.module.scss';
import ExtensionIcon from '@mui/icons-material/Extension';
import { App } from '../../services/object/App';

import { useApp } from "../../services/auth/useApp";

const LienUtile = () => {
  const { TOApp } = useApp();
  const filteredTOApp = TOApp.filter((app: App) => app.isJraApp === false);
  console.log('filteredTOApp:', filteredTOApp);
  

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        handleClose();
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        className={style.popperRoot}
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} timeout={350}>
            <Paper className={`${style.popperContent} hoverShadow`}>
              <div className={style.iconUtile}>
                <ul>
                  {filteredTOApp.map((app: App, index: number) => (
                    <li key={index}>
                      <NavLink target="_blank" to={app.url}>
                        <Button color='info' variant="text" className={style.button}>
                          {app.photo ? (
                            <img src={app.photo} alt={app.name} />
                          ) : (
                            <ExtensionIcon />
                          )}
                          <p>{app.name}</p>
                        </Button>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </Paper>
          </Slide>
        )}
      </Popper>
      <IconButton size="small" sx={{ marginBlock: '0.3rem', marginInline: '0.5rem' }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
}

export default LienUtile;
